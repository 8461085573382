import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { ReportType, TypeOfReport } from '../../types/reports';
import { KpiStatus, REPORT_KEYS } from '../../constants';
import { formatYYYYMMDateToMMMMYString, is, plural } from '../../utils';
import { TableBody } from '@mui/material';
import { getFinalBonus } from './Reports.utils';
import { useTheme } from '@mui/material/styles';
import { priceFormat } from '../../components/tableCellRenders';
import { ActionTakerBadge } from '../../components/actionTaker/ActionTaker';
import * as _ from 'lodash';
import { capitalizeFirstLetter } from '../globalTracker/utils';
import DepartmentRow from './components/compensationReport/DepartmentRow';

interface Props {
  reports: ReportType[];
  typeOfReport: TypeOfReport | null;
}

const ReportsTable: React.FC<Props> = ({ reports, typeOfReport }) => {
  return (
    <TableContainer sx={{ overflowX: 'initial' }}>
      <Table stickyHeader size="small" aria-label="reports-table">
        <ReportsHeader reports={reports} typeOfReport={typeOfReport} />
        <ReportsBody reports={reports} typeOfReport={typeOfReport} />
      </Table>
    </TableContainer>
  );
};

interface ReportsHeaderProps {
  reports: ReportType[];
  typeOfReport: TypeOfReport | null;
}

const ReportsHeader: React.FC<ReportsHeaderProps> = ({
  reports,
  typeOfReport,
}) => {
  const hasTrueUp = useMemo(() => {
    return !!(reports || []).find(({ trueup }) => !is.nullOrUndefined(trueup));
  }, [reports]);

  const trueUpHeader = hasTrueUp ? (
    <>
      <TableCell align={'right'} sx={{ pr: 4.5 }}>
        {REPORT_KEYS.trueUp}
      </TableCell>
      <TableCell align={'right'} sx={{ pr: 4.5 }}>
        {REPORT_KEYS.finalBonus}
      </TableCell>
    </>
  ) : null;

  return (
    <TableHead>
      {typeOfReport === TypeOfReport.TEAM_COMPENSATION_PLAN ? (
        <TableRow>
          <TableCell>{REPORT_KEYS.fullName}</TableCell>
          <TableCell>{REPORT_KEYS.role}</TableCell>
          <TableCell>{REPORT_KEYS.Seniority}</TableCell>
          <TableCell>{REPORT_KEYS.kpiMetricName}</TableCell>
          <TableCell>{REPORT_KEYS.frequency}</TableCell>
          <TableCell>{REPORT_KEYS.worstCase}</TableCell>
          <TableCell>{REPORT_KEYS.bestCase}</TableCell>
          <TableCell>{REPORT_KEYS.targetBonus}</TableCell>
          <TableCell>{REPORT_KEYS.baseCompensation}</TableCell>
          <TableCell>{REPORT_KEYS.compensationType}</TableCell>
          <TableCell>{REPORT_KEYS.month}</TableCell>
          <TableCell sx={{ pr: 3 }}>{REPORT_KEYS.year}</TableCell>
          <TableCell>{REPORT_KEYS.location}</TableCell>
          <TableCell>{REPORT_KEYS.averageMonthlyHours}</TableCell>
          <TableCell>{REPORT_KEYS.department}</TableCell>
          <TableCell>{REPORT_KEYS.subDepartment}</TableCell>
          <TableCell>{REPORT_KEYS.reportingTo}</TableCell>
          <TableCell>{REPORT_KEYS.kpiOwner}</TableCell>
        </TableRow>
      ) : (
        <TableRow>
          <TableCell sx={{ pl: 3 }}>{REPORT_KEYS.department}</TableCell>
          <TableCell>{REPORT_KEYS.bonusType}</TableCell>
          <TableCell>{REPORT_KEYS.fullName}</TableCell>
          <TableCell>{REPORT_KEYS.metricName}</TableCell>
          <TableCell>Period</TableCell>
          <TableCell align={'right'} sx={{ pr: 4.5 }}>
            {REPORT_KEYS.bonus}
          </TableCell>
          {trueUpHeader}
          <TableCell>{REPORT_KEYS.location}</TableCell>
          <TableCell>{REPORT_KEYS.frequency}</TableCell>
          <TableCell sx={{ pr: 3 }}>{REPORT_KEYS.city}</TableCell>
        </TableRow>
      )}
    </TableHead>
  );
};

interface ReportsBodyProps {
  reports: ReportType[];
  typeOfReport: TypeOfReport | null;
}

const ReportsBody: React.FC<ReportsBodyProps> = ({ reports, typeOfReport }) => {
  const { palette, spacing } = useTheme();

  const groupedReportsByDept = useMemo(
    () => _.groupBy(reports, 'department'),
    [reports],
  );

  console.log('@groupedReportsByDept');
  console.log(JSON.stringify(groupedReportsByDept, null, 2));

  const { deptBonusCompMap, groupedReports } = useMemo(() => {
    const deptBonusCompMap: any = {};
    const groupedReports: any = {};
    Object.keys(groupedReportsByDept).forEach((k) => {
      deptBonusCompMap[k] = {
        totalBonus: groupedReportsByDept[k].reduce(
          (total, rep) =>
            rep.bonusShare && !rep.isGroupedDataDetail
              ? total + rep.bonusShare
              : total + 0,
          0,
        ),
        totalComp: groupedReportsByDept[k].reduce(
          (total, rep) =>
            rep.baseCompensation && !rep.isGroupedDataDetail
              ? total + rep.baseCompensation
              : total + 0,
          0,
        ),
      };

      groupedReports[k] = _.groupBy(groupedReportsByDept[k], 'subDepartment');
    });

    return { deptBonusCompMap, groupedReports };
  }, [groupedReportsByDept]);

  console.log('@groupedReports');
  console.log(JSON.stringify(groupedReports, null, 2));

  console.log('@deptBonusCompMap');
  console.log(JSON.stringify(deptBonusCompMap, null, 2));

  const apporvedReports = useMemo(
    () => reports.filter((report) => report.status === KpiStatus.Approved),
    [reports],
  );
  const unapporvedReports = useMemo(
    () => reports.filter((report) => report.status !== KpiStatus.Approved),
    [reports],
  );

  const hasTrueUp = useMemo(() => {
    return !!(reports || []).find(({ trueup }) => !is.nullOrUndefined(trueup));
  }, [reports]);

  const renderFinalBonus = (report: ReportType) => {
    if (!hasTrueUp) {
      return null;
    }

    const finalBonus = getFinalBonus(report);

    return (
      <>
        <TableCell align={'right'} sx={{ fontWeight: '500' }}>
          {priceFormat({ value: report.trueup })}
        </TableCell>
        <TableCell align={'right'} sx={{ fontWeight: '500' }}>
          {priceFormat({ value: finalBonus })}
        </TableCell>
      </>
    );
  };

  const renderRow = (report: ReportType, index: number) => (
    <TableRow
      key={`${report.fullName}_${index}`}
      hover={true}
      className="no-height"
    >
      <TableCell sx={{ pl: 3, maxWidth: '180px' }}>
        {report.department.replaceAll('&amp;', '&')}
      </TableCell>
      <TableCell>{report.bonusType}</TableCell>
      <TableCell>{report.fullName}</TableCell>
      <TableCell>{report.metricName}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {formatYYYYMMDateToMMMMYString(report.period)}
      </TableCell>
      {report.payout !== null &&
      report.payout !== undefined &&
      report.status === KpiStatus.Approved ? (
        <TableCell align={'right'} sx={{ fontWeight: '500' }}>
          {priceFormat({ value: report.payout })}
        </TableCell>
      ) : (
        <TableCell align={'right'} sx={{ fontWeight: '500' }}>
          {report.actionTaker && (
            <ActionTakerBadge
              status={report.status}
              actionTaker={report.actionTaker}
            />
          )}
        </TableCell>
      )}

      {renderFinalBonus(report)}

      <TableCell>{report.location}</TableCell>
      <TableCell>{capitalizeFirstLetter(report.frequency)}</TableCell>
      <TableCell sx={{ pr: 3 }}>{report.city || 'N/A'}</TableCell>
    </TableRow>
  );

  return (
    <TableBody>
      {typeOfReport === TypeOfReport.TEAM_COMPENSATION_PLAN ? (
        Object.keys(groupedReports).map((dep) => (
          <DepartmentRow
            key={dep}
            depName={dep}
            depReports={groupedReports[dep]}
            {...deptBonusCompMap[dep]}
          />
        ))
      ) : (
        <>
          {!!unapporvedReports.length && (
            <>
              <TableRow className="no-height">
                <TableCell
                  colSpan={11}
                  sx={{
                    height: 48,
                    fontWeight: 500,
                    padding: `0 ${spacing(3)}`,
                    border: 'none',
                    color: palette.error.main,
                    background: palette.highlight.error,
                  }}
                >
                  {`${unapporvedReports.length} metric${plural(
                    unapporvedReports.length,
                  )} need results and approval`}
                </TableCell>
              </TableRow>
              {unapporvedReports.map(renderRow)}
              {!!apporvedReports.length && (
                <TableRow className="no-height">
                  <TableCell
                    colSpan={11}
                    sx={{
                      height: 48,
                      fontWeight: 500,
                      padding: `0 ${spacing(3)}`,
                      border: 'none',
                      color: palette.success.main,
                      background: palette.highlight.success,
                    }}
                  >
                    Approved
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
          {apporvedReports.map(renderRow)}
        </>
      )}
    </TableBody>
  );
};

export default ReportsTable;
